import React from 'react';
import { Link } from 'react-router-dom';
import "./works.css";

const Works = () => {
    return (
        <section className="works section" id="works">
            <div className="works__container container grid">
                <div className="works__content grid" id="works">
                    <Link to = "/papersing">
                        <div className="work__item">
                            <div className="papersing__img work__img"></div>
                            <div className="work__title">Paper Sing</div>
                            <div className="work__subtitle">2022 - 2023・Art</div>
                        </div>
                    </Link>
                    <Link to = "/perport">
                        <div className="work__item">
                            <div className="perport__img work__img"></div>
                            <div className="work__title">PerPort</div>
                            <div className="work__subtitle">2021 - 2024・Research, Mobile App</div>
                        </div>
                    </Link>
                    <Link to = "/mistyfire">
                        <div className="work__item">
                            <div className="mistyfire__img work__img"></div>
                            <div className="work__title">Misty Fire as Life</div>
                            <div className="work__subtitle">2020・Art</div>
                        </div>
                    </Link>
                    <Link to = "/onawa">
                        <div className="work__item">
                            <div className="onawa__img work__img"></div>
                            <div className="work__title">深海大縄跳び</div>
                            <div className="work__subtitle">2023・Entertainment</div>
                        </div>
                    </Link>
                    <Link to = "/tomato">
                        <div className="work__item">
                            <div className="tomato__img work__img"></div>
                            <div className="work__title">mutable(tomato | you)</div>
                            <div className="work__subtitle">2023・Art</div>
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Works